// import Layout from './Layout';
// import Image from 'next/image';

export default function Header({ companyLogo }: { companyLogo: string }) {
    const logo = companyLogo || '/logo-fg.png';
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <img src={logo} alt="Frete Gestão" width={320} height={0} style={{ height: 'auto' }} />
        <h4 style={{ color: "#333", margin: "10px 0" }}>Você está acessando nosso aplicativo de</h4>
        <h2 style={{ color: "#333", margin: "0 0 10px 0" }}><strong>Confirmação de Entrega</strong></h2>
      </div>
    );
  }