import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";
import SignaturePad from 'react-signature-pad-wrapper';
import "./index.css";
import Header from './components/Header';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';

function MainComponent() {
  interface Company {
    uuid: string;
    razao_social: string;
    cnpj: string;
    logo: string;
  }

  interface DetectedBarcode {
    rawValue: string;
  }

  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState({ uuid: '', razao_social: '', cnpj: '', logo: '' });
  const [logo, setLogo] = useState('');
  const [isReaderOpened, setIsReaderOpened] = useState(false);
  const [extractedData, setExtractedData] = useState<Record<string, { name: string; value: string }>>({});
  const [readKey, setReadKey] = useState('');
  const [read, setRead] = useState(false);
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [image3, setImage3] = useState<File | null>(null);
  
  const signaturePadRef = useRef<SignaturePad>(null);

  const { token } = useParams<{ token: string }>();

  const transformImage = async (image: File) => {

  }

  const handleCapture = async (barcodes: { rawValue: string }[]) => {
    if (barcodes.length === 0) return;
    const { rawValue: key } = barcodes[0];

    if (key.length !== 44) {
      alert("Chave de acesso deve ter 44 dígitos.");
      setExtractedData({});
      setRead(false);
      return;
    }

    const extractedData = {
      doc: { name: 'CNPJ', value: key.slice(6, 20) },
      invoiceNum: { name: 'Número', value: key.slice(25, 34) },
      invoiceSerie: { name: 'Série', value: key.slice(22, 25) },
    };

    if (extractedData.doc.value !== '' && extractedData.doc.value !== selectedCompany.cnpj) {
      alert("CNPJ do emissor da Nota Fiscal não corresponde ao CNPJ da empresa logada.");
      setExtractedData({});
      setRead(false);
      setIsReaderOpened(false);
      return;
    }

    if (!token) {
      alert("Token não encontrado.");
      return;
    }

    const dbKey = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/delivery-app/invoices/${selectedCompany.uuid}/${key}`, {
      method: 'GET',
      headers: {
        'x-customer-token': token,
      },
    });
    const keyDB = await dbKey.json();
    if (keyDB.status === 'success') {
      alert("Nota Fiscal já entregue.");
      setExtractedData({});
      setRead(false);
      setIsReaderOpened(false);
      return;
    }

    setReadKey(key);
    setExtractedData(extractedData);
    setIsReaderOpened(false);
    setRead(true);
  };

  const handleConfirmReader = async () => {
    const receiverName = (document.querySelector('input[name="receiver_name"]') as HTMLInputElement).value;
    const receiverDoc = (document.querySelector('input[name="receiver_doc"]') as HTMLInputElement).value;
    const { invoiceNum, invoiceSerie } = extractedData;

    if (!signaturePadRef.current) {
      alert('A assinatura é obrigatória.');
      return;
    }

    const formData = new FormData();
    formData.append('invoiceNum', invoiceNum.value);
    formData.append('invoiceSerie', invoiceSerie.value);
    formData.append('receiverName', receiverName);
    formData.append('receiverDoc', receiverDoc);
    formData.append('readKey', readKey);
    formData.append('company', selectedCompany.uuid);

    const signatureImage = signaturePadRef.current.toDataURL('image/png');
    // Converte a imagem para Blob e cria um FormData para envio
    const blob = await (await fetch(signatureImage)).blob();
    formData.append('signature', blob, `signature_${invoiceNum.value}_${selectedCompany.cnpj}.png`);

    // if (image1) formData.append('image1', image1);
    // if (image2) formData.append('image2', image2);
    // if (image3) formData.append('image3', image3);
    
    // Redução de tamanho e compressão
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    if (image1) {
      const originalName1 = image1.name.split('.').slice(0, -1).join('.');
      const originalExtension1 = image1.name.split('.').pop();

      // Conversão HEIC para JPEG
      if (image1 && originalExtension1 && originalExtension1.toLowerCase() === 'heic') {
        try {
          const convertedBlob = await heic2any({ blob: image1, toType: 'image/jpeg' });
          setImage1(new File([convertedBlob as Blob], `${originalName1}.jpg`, { type: 'image/jpeg' }));
        } catch (error) {
          console.error('Erro ao converter HEIC para JPEG na imagem 1:', error);
          return;
        }
      }

      // Redução de tamanho e compressão
      try {
        const compressedFile1 = await imageCompression(image1, options);
        formData.append('image1', compressedFile1, `image1_${invoiceNum.value}_${selectedCompany.cnpj}.png`);
      } catch (error) {
        console.error('Erro ao comprimir a imagem 1:', error);
      }
    }

    await fetch(`${process.env.REACT_APP_MAIN_API_URL}/delivery-app/invoices`, {
      method: 'POST',
      headers: token ? { 'x-customer-token': token } : {},
      body: formData,
    });

    setExtractedData({});
    setRead(false);
    setIsReaderOpened(false);
    setReadKey('');
    alert('Confirmação de entrega realizada com sucesso!');
  };

  const handleCancelReader = () => {
    setExtractedData({});
    setRead(false);
    setIsReaderOpened(false);
    setReadKey('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchCompanies = async () => {
          try {
            const companiesRes = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/delivery-app/companies`, {
              method: 'GET',
              headers: token ? { 'x-customer-token': token } : {},
            });
            const companies = await companiesRes.json();
            if (!companies) {
              throw new Error('Failed to fetch companies');
            }
            return companies;
          } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
          }
        };

        const companies = await fetchCompanies();
        setCompanies(companies.data);

        if (companies.length === 0) {
          alert("Empresa(s) do cliente não encontrada(s).");
          setExtractedData({});
          setRead(false);
          setLogo('');
          setReadKey('');
          return;
        } else if (companies.length === 1) {
          setSelectedCompany(companies[0]);
          setLogo(companies[0].logo);
        }
      } catch {
        alert("Empresa(s) do cliente não encontrada(s).");
        setExtractedData({});
        setRead(false);
        setReadKey('');
      }
    };

    fetchData();
  }, [token]);

  return (
    <div className="container">
      <Header companyLogo={logo} />
      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
      <div className="screen">
        {companies.length > 1 && (
          <select
            style={{
              margin: "10px 0",
              padding: "10px 20px",
              backgroundColor: "#f37f00",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px"
            }}
            onChange={(e) => {
              const selectedCompany = companies.find(company => company.uuid === e.target.value);
              if (selectedCompany) {
                setSelectedCompany(selectedCompany);
                setLogo(selectedCompany.logo);
              } else {
                setSelectedCompany({ uuid: '', razao_social: '', cnpj: '', logo: '' });
                setLogo('');
              }
            }}
          >
            <option value="">Selecione a empresa</option>
            {companies.map((company) => (
              <option key={company.uuid} value={company.uuid}>{company.razao_social}</option>
            ))}
          </select>
        )}

        {!read && selectedCompany.uuid !== '' && (
          <button
            style={{
              margin: "10px 0",
              padding: "10px 20px",
              backgroundColor: "#f37f00",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px"
            }}
            onClick={() => setIsReaderOpened(!isReaderOpened)}
          >
            {!isReaderOpened ? "Iniciar" : "Parar"} Leitura de Nota Fiscal
          </button>
        )}
        {isReaderOpened && (
          <BarcodeScanner
            options={{ formats: ["code_128"] }}
            // onCapture={(barcode) => handleCapture(barcode)}
            onCapture={(barcodes: DetectedBarcode[]) => {
              handleCapture(barcodes);
            }}
          />
        )}
        {read && (
          <div style={{ paddingBottom: "40px"}}>
            <h3>Informações da Nota Fiscal</h3>
            <ul>
              {Object.entries(extractedData).map(([key, val]) => (
                <li key={key}>
                  {val.name}: <strong>{val.value}</strong>
                </li>
              ))}
            </ul>
            <h3>Informações do Recebedor</h3>
            <div>
              <input type="text" name="receiver_name" placeholder="Nome do Recebedor" style={{ padding: "10px", fontSize: "16px", width: "90%" }} />
            </div>
            <div>
              <input type="number" name="receiver_doc" placeholder="Documento do Recebedor" style={{ padding: "10px", fontSize: "16px", width: "90%", marginTop: "8px" }} />
            </div>
            <div>
              <p>Assinatura:</p>
              <hr />
              <SignaturePad ref={signaturePadRef} redrawOnResize options={{ penColor: 'rgb(0, 0, 0)', backgroundColor: 'rgb(255, 255, 255)' }} />
              <hr />
              <button
                style={{
                padding: "10px 20px",
                backgroundColor: "#f37f00",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "12px"
                }}
                onClick={() => {
                const signaturePad = document.querySelector('canvas') as HTMLCanvasElement;
                if (signaturePad) {
                  const context = signaturePad.getContext('2d');
                  if (context) {
                  context.clearRect(0, 0, signaturePad.width, signaturePad.height);
                  }
                }
                }}
              >Limpar</button>
            </div>
            <div>
              <p>Imagem 1:</p>
              <input type="file" accept="image/*" onChange={(e) => setImage1(e.target.files ? e.target.files[0] : null)} />
            </div>
            <div>
              <p>Imagem 2:</p>
              <input type="file" accept="image/*" onChange={(e) => setImage2(e.target.files ? e.target.files[0] : null)} />
            </div>
            <div>
              <p>Imagem 3:</p>
              <input type="file" accept="image/*" onChange={(e) => setImage3(e.target.files ? e.target.files[0] : null)} />
            </div>
            <div>
              <p>Ao assinar, você confirma o recebimento da mercadoria em perfeitas condições e a entrega da nota fiscal correspondente, conforme as informações apresentadas.<br /><br />
                Caso tenha alguma dúvida ou divergência, solicitamos que entre em contato imediatamente com a nossa central de atendimento.<br /><br />
                A assinatura também valida que não há pendências relacionadas à entrega, exceto aquelas previamente acordadas.</p>
            </div>
            <button
              style={{
                margin: "10px 0",
                padding: "10px 20px",
                backgroundColor: "#f37f00",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px"
              }}
              onClick={() => handleConfirmReader()}
            >
              Confirmar leitura
            </button>
            <button
              style={{
                margin: "10px 0 0 5px",
                padding: "10px 20px",
                backgroundColor: "#aaa",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px"
              }}
              onClick={() => handleCancelReader()}
            >
              Cancelar leitura
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Defina a rota principal com o parâmetro opcional */}
        <Route path="/:token" element={<MainComponent />} />
        {/* Também pode incluir a rota raiz sem parâmetros */}
        <Route path="/" element={<MainComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
